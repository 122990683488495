.wrapper {
}

.headerForm {
    display: flex;
    flex-direction: row;
}

.fetchButton {
    align-self: center;
    padding: 3px 6px;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    font-size: inherit;
    line-height: 1.5em;
    cursor: pointer;
}

.batches {
    padding: .25em .75em;
}