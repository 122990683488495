.instruction {
    display: flex;
    flex-direction: row;
    padding-bottom: .5em;
    margin-bottom: .5em;
}

.instructionGutter {
    flex: 0;
    padding: 0 .75em;
    color: #bbb;
}

.instructionContent {
    display: flex;
    flex-direction: column;
}

.instructionHeader {
}

.instructionDetails {
    color: #aaa;
}

.instructionDetails span::after {
    content: ' ';
}

.instructionDetails a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px dotted transparent;
}

.instructionDetails a:hover {
    border-bottom-color: inherit;
}

.instructionAnswer {
    padding-top: .5em;
    padding-right: .75em;
}

.stringAnswer {

}

.stringAnswer::before {
    content: '“';
    color: #888;
}

.stringAnswer::after {
    content: '”';
    color: #888;
}