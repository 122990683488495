.sortButton {
    display: flex;
    flex-direction: column;
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
}

.sortButtonArrow {
    line-height: 1em;
}