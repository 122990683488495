.pastedFoilsList {
    text-align: left;
}

.pastedFoilsItem {

}

.pastedFoilCheck {
    margin-left: .5em;
    font-size: .9em;
}