.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .25em 0;
}

.queryInput {
    box-sizing: border-box;
    margin: 0 .75em;
    padding: .5em .75em;
    background: #fff;
    border-radius: 100px;
    border: 1px solid #f0f0f0;
    font-size: inherit;
    line-height: inherit;
}

.queryInput:focus {
    outline: none;
    border-color: #00abdd;
    box-shadow: inset 0 0 0 1px #00abdd
}

.wrapperLabel {
    margin: 0 .75em;
}

.filter {
    display: flex;
    flex-direction: row;
    margin-right: .75em;
    padding: .5em .75em;
    background: #f0f0f0;
    border-radius: 100px;
    cursor: pointer;
    user-select: none;
}

.filterLabel {
    margin-left: .5em;
}