
.buttonsWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0 .75em;
}

.buttons > * {
    padding: 3px 6px;
    background: #fff;
    border: 1px solid #eaeaea;
    font-size: inherit;
    line-height: 1.5em;
    cursor: pointer;
}

.buttons > *:first-child {
    border-radius: 4px 0 0 4px;
}

.buttons > *:last-child {
    border-radius: 0 4px 4px 0;
}

.buttons > *:not(:last-child) {
    border-right: none;
}

.buttons > *:hover {
    background-color: #f5f5f5;
}

.buttons > *:focus {
    outline: none;
    background-color: #eaeaea;
}