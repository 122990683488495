.recording {
    border-bottom: 1px solid var(--borderColor);
}

.recording:last-child {
    border-bottom: none;
}

.recordingHeader {
    padding: .5em .75em;
    margin-bottom: .5em;
}

.recordingContent {

}

.finishedBadge {
    margin-left: .75em;
    padding: .15em .65em;
    background: limegreen;
    border-radius: 20px;
    line-height: 1em;
    font-weight: bold;
    font-size: .9em;
    color: white;
}