
.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5em 0;
}

.footerArrow {
    width: 3%;
    text-align: center;
}

.footerCount {
    font-weight: bold;
}

.footerClearButton {
    margin-left: .75em;
    padding: 3px 6px;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    font-size: inherit;
    line-height: 1.5em;
    cursor: pointer;
}

.footerClearButton:hover {
    background-color: #f5f5f5;
}

.footerClearButton:focus {
    outline: none;
}