
.answerTableWrapper {
    --tableBorderColor: #eee;
    border: 1px solid var(--tableBorderColor);
    border-radius: 4px;
}

.answerTable {
    border-collapse: collapse;
}

.answerTable tr > * {
    min-width: 10em;
    padding: .25em .5em;
}

.answerTable th {
    background: var(--tableBorderColor);
    border-right: 1px solid #fff;
    font-weight: normal;
    text-align: left;
}

.answerTable th:last-child {
    border-right: none;
}

.filter {
    margin-top: .25em;
    padding-left: .5em;
    color: #aaa;
}

.filterButton {
    margin-left: .5em;
    border-bottom: 1px solid;
    cursor: pointer;
}