.appLayout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.warningLayout {
  width: 800px;
  margin: 3em auto;
  padding: 1em;
  background: #fafafa;
}