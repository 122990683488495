
.loadingWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,.8);
    transition: 50ms opacity ease-in-out;
}

@keyframes ball-scale {
    0% {
      transform: scale(0);
    }
  
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  .ballScale > div {
    background-color: #00abdd;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    display: inline-block;
    height: 60px;
    width: 60px;
    animation: ball-scale 1s 0s ease-in-out infinite;
  }
  