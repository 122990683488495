.module {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
}

.moduleColumns {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
}

.moduleColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.inputTableWrapper {
    flex: 1;
    overflow-y: scroll;
}

.outputTableWrapper {
    max-height: 30%;
    box-shadow: 0 -2px 3px rgba(0,0,0,.1);
    overflow-y: scroll;
}
  