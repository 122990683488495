.table {
    position: relative;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.tableHeadRow {
    height: 32px;
}

.tableHeadGroupCell {
    position: sticky;
    top: var(--sticky-top, 0);
    background: #fff;
    text-align: center;
}

.tableHeadCell {
    position: sticky;
    top: var(--sticky-top, 0);
    background: #fff;
    box-shadow: 2px 2px 6px -2px rgba(0,0,0,.2);
    text-align: left;
}

.tableHeadCellContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .5em .25em;
}

.tableRow {
    height: 32px;
}

.tableRow:nth-child(2n+1) {
    background: #fafafa;
}

.tableRow:hover {
    background: #eaeaea;
}

.tableRow:hover:nth-child(2n+1) {
    background: #eaeaea;
}

.tableRow.tableRowSelected {
    background: #d3eef7;
}

.tableRow.tableRowSelected:nth-child(2n+1) {
    background: #bee3ef;
}

.tableCheckboxCell {
    text-align: center;
}

.tableOrderCell {
    padding: .5em;
    text-align: right;
}

.tableCell {
    padding: .5em .25em;
}

.tableRow > .tableCellSelected {
    background: #d3eef7;
}

.tableRow:nth-child(2n+1) > .tableCellSelected {
    background: #bee3ef;
}