.batch {
    --borderColor: #eaeaea;
    margin-bottom: .5em;
    border: 1px solid var(--borderColor);
    border-radius: 4px;
}

.batchHeader {
    display: flex;
    flex-direction: row;
    background: var(--borderColor);
}

.batchHeaderChevron {
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: .5em;
}

.batchHeaderChevron button {
    display: flex;
    width: 1.5em;
    height: 1.5em;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
}

.batchHeaderTitle {
    flex-grow: 1;
    padding: .5em .75em .5em .5em;
}

.batchHeaderAttachments {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5em .75em .5em .5em;
}

.batchHeaderAttachment {    
    margin-left: .75em;
    font-size: .9em;
    color: inherit;
    text-decoration: none;
}

.batchHeaderAttachmentIcon {
    font-size: .9em;
    margin-right: .25em;
}

.batchHeaderAttachmentLabel {
    border-bottom: 1px dotted transparent;
}

.batchHeaderAttachment:hover > .batchHeaderAttachmentLabel {
    border-bottom: 1px dotted rgba(0,0,0,.65);
}

.archivedBadge {
    margin-left: .75em;
    padding: .15em .65em;
    background: #cacaca;
    border-radius: 20px;
    line-height: 1em;
    font-weight: bold;
    font-size: .9em;
}

.batchNotes {
    white-space: pre-wrap;
}

.batchContent {
    display: flex;
    flex-direction: column;
}