.tableInputWrapper {
    display: flex;
    flex-direction: row;
}

.tableInputPencil {
    align-self: center;
    display: flex;
    width: 1.5em;
    justify-content: center;
}

.tableInput {
    box-sizing: border-box;
    width: 100%;
    margin-left: -1.5em;
    padding: .5em .25em .5em 1.5em;
    background: transparent;
    border: 0;
    font-size: inherit;
}

.tableInput:focus {
    outline: 2px solid #00abdd;
}