.topHeader {
    display: flex;
    flex-direction: row;
    background: #fafafa;
    padding: .25em 0;
    margin-bottom: .5em;
}

.bottomHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}