html, body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background:rgba(0,0,0,0.1);
}

::-webkit-scrollbar-track {
	/* background:rgba(0,0,0,0.05); */
}

::-webkit-scrollbar-thumb:hover {
	background:rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:window-inactive {
	background:rgba(0,0,0,0.02);
}