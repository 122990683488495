.field {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .25em .75em;
}

.fieldLabel {
    font-weight: bold;
    margin-right: .75em;
}

.fieldInput {
    flex: 1;
    padding: 6px 10px;
    font-size: 14px;
    line-height: 1.52857143;
    color: #3a3f51;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #dde6e9;
    border-radius: 4px;
}

.fieldInput:focus {
    border-color: #00abdd;
    outline: none;
}

